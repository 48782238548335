'use client'

import Lucide from '@/components/Base/Lucide'

type ErrorProps = {
    error: Error
    reset: () => void
}

export default function ErrorPage({ error, reset }: ErrorProps) {
    return (
        <div className='flex h-screen w-screen flex-col items-center justify-center gap-4'>
            <Lucide icon='OctagonAlert' className='h-[80px] w-[80px]' />
            <h2 className='text-semibold text-2xl font-medium'>
                {error.message}
            </h2>
            <button onClick={() => reset()} className='text-danger'>
                Try again
            </button>
        </div>
    )
}
